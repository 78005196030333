<template>
  <v-row>
    <v-col cols="12">
      <Column double />
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    Column: () => import('../components/Column.vue'),
  },
}

</script>
